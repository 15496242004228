import { Load } from './load';

export class DispatchTrip {
  id: number;
  status: TripStatus;
  startCity: string;
  startState: string;
  endCity: string;
  endState: string;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  actualStartDateTime?: Date;
  actualEndDateTime?: Date;
  pickupCount: number;
  deliveryCount: number;
  load: Array<Load>;
}

export enum TripStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}
