export const ADD_LOAD = 'ADD_LOAD';
export const SELECT_LOAD = 'SELECT_LOAD';
export const SET_OPEN_LOAD_EDIT = 'SET_OPEN_LOAD_EDIT';
export const LOAD_LOADS_SUCCESS = 'LOAD_LOADS_SUCCESS';
export const UPDATE_LOAD = 'UPDATE_LOAD';
export const DELETE_LOAD = 'DELETE_LOAD';
export const SET_LOAD_CHECKED = 'SET_LOAD_CHECKED';
export const UNCHECK_ALL_LOADS = 'UNCHECK_ALL_LOADS';
export const SET_LOAD_CHANGED = 'SET_LOAD_CHANGED';
export const SET_LOAD_TRUCK = 'SET_LOAD_TRUCK';
export const SET_LOAD_DRIVER = 'SET_LOAD_DRIVER';
export const CHECK_ALL_LOADS = 'CHECK_ALL_LOADS';
export const SET_OPEN_UNSAVED_DIALOG = 'SET_OPEN_UNSAVED_DIALOG';
export const SET_UNSAVED_DIALOG_FINAL_ACTION = 'SET_UNSAVED_DIALOG_FINAL_ACTION';
export const SET_DISPLAYED_LOADS = 'SET_DISPLAYED_LOADS';
export const SORT_LOADS = 'SORT_LOADS';
export const INSERT_LOAD_LIST_ITEM = 'INSERT_LOAD_LIST_ITEM';
export const UPDATE_LOAD_SUMMARY = 'UPDATE_LOAD_SUMMARY';
export const REMOVE_CHECKED_LOAD = 'REMOVE_CHECKED_LOAD';
export const SET_LOAD_MESSAGE_STATUS = 'SET_LOAD_MESSAGE_STATUS';
export const CLEAR_LOAD_MESSAGE_STATUS = 'CLEAR_LOAD_MESSAGE_STATUS';
export const HIGHLIGHT_NEW_LOAD = 'HIGHLIGHT_NEW_LOAD';
export const SHOW_INVOICING_JOYRIDE = 'SHOW_INVOICING_JOYRIDE';
export const ADD_LOAD_FOR_INVOICING = 'ADD_LOADS_FOR_INVOICING';
export const CLEAR_LOAD_FOR_INVOICING = 'CLEAR_LOADS_FOR_INVOICING';
export const SET_DOWNLOAD_FLAG = 'SET_DOWNLOAD_FLAG';
export const SET_LOAD_SNACKBAR_CONFIG = 'SET_LOAD_SNACKBAR_CONFIG';
export const ADD_FINISHED_INVOICE_STATUS = 'ADD_FINISHED_INVOICE_STATUS';
export const CLEAR_FINISHED_INVOICE_STATUS = 'CLEAR_FINISHED_INVOICE_STATUS';
export const CLEAR_ALL_LOAD_INVOICE_STATUS = 'CLEAR_ALL_LOAD_INVOICE_STATUS';
