export enum FeatureFlags {
  LM_TRIPS = 'TMS-1892-WEB',
  MAINTENANCE_BYPASS = 'TMS-Maintenance',
  WEX_FUEL_UPDATES = 'TMS-3720-WEB',
  PT_BASIC_TRIAL = 'CPUA-4769-WEB',
  REMOVE_UPGRADE_BANNER = 'TMS-4207',
  INVOICE_PREVIEW_AND_RESUBMISSION = 'TMS-4000-WEB',
  COPY_LOAD = 'TMS-4395-WEB',
  FACTORING_MAINTENANCE = 'TMS-FACTORING-MAINTENANCE',
  DISPATCH = 'TMS-4499-WEB',
  SIDE_NAVIGATION = 'FUEL-4073',
  WEX_INTEGRATION_AUTOMATION = 'TMS-4211-WEB',
}
