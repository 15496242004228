import { DriverSchedule } from '../../interfaces/driver-schedule';
import * as DispatchConstants from '../../constants/dispatchConstants';
import { DispatchDriver } from '../../interfaces/dispatch-driver';
import { addDays, getMonday, getSunday } from '../../components/dispatch/dispatch-utils';

interface DispatchStoreState {
  startDate: Date;
  endDate: Date;
  drivers: DispatchDriver[];
  startDateBoundary: Date;
  endDateBoundary: Date;
  driverSchedules: DriverSchedule[];
  filteredDriverSchedules: DriverSchedule[];
  searchCriteria: string;
  isLoading: boolean;
}

const initialState: DispatchStoreState = {
  drivers: [],
  startDate: getMonday(new Date()),
  endDate: getSunday(new Date()),
  startDateBoundary: getMonday(addDays(new Date(), -7)),
  endDateBoundary: getSunday(addDays(new Date(), 21)),
  driverSchedules: [],
  filteredDriverSchedules: [],
  searchCriteria: '',
  isLoading: false,
};

export default function dispatchReducer(state = initialState, action: any) {
  switch (action.type) {
    case DispatchConstants.SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case DispatchConstants.SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case DispatchConstants.SET_DRIVER_SCHEDULES:
      return {
        ...state,
        isLoading: false,
        driverSchedules: action.schedules,
      };
    case DispatchConstants.SET_FILTERED_DRIVER_SCHEDULES:
      return {
        ...state,
        filteredDriverSchedules: action.schedules,
      };
    case DispatchConstants.SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.searchCriteria,
      };
    case DispatchConstants.SET_DRIVERS:
      return {
        ...state,
        drivers: action.drivers,
      };
    case DispatchConstants.SET_DISPATCH_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}
