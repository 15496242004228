export enum FeatureFlags {
  LM_TRIPS = 'TMS-1892-WEB',
  MAINTENANCE_BYPASS = 'TMS-Maintenance',
  PT_BASIC_TRIAL = 'CPUA-4769-WEB',
  REMOVE_UPGRADE_BANNER = 'TMS-4207',
  COPY_LOAD = 'TMS-4395-WEB',
  FACTORING_MAINTENANCE = 'TMS-FACTORING-MAINTENANCE',
  DISPATCH = 'TMS-4499-WEB',
  SIDE_NAVIGATION = 'FUEL-4073',
  WEX_INTEGRATION_AUTOMATION = 'TMS-4211-WEB',
  ADVANCED_LOADS = 'f6345c6d57db87fda0e6fcabe4fa3f39566dde889ee35b98d2a765f951becc08',
  NEW_DEBTOR_SEARCH = '4bf6748daf88aff2adcdaedfef627572c3d380ff8f1554e6e2478cc97b8e7006',
}
