import { Partner, Stop, Invoice } from '.';
import { Driver } from './driver';
import { Truck } from './truck';
import { TrailerType } from './trailer-type';
import { Trailer } from './trailer';

export class Load {
  id: number;
  loadNumber: string;
  companyId: number;
  partnerId: string;
  contactName: string;
  contactPhoneNumber: string;
  amount: any;
  poNumber: string;
  isInvoiced: boolean;
  isPaid: boolean;
  invoicePaidOn?: any;
  invoicePaidOnOffset?: any;
  transactionKey: string;
  createdOn: number;
  createdBy: string;
  modifiedOn: number;
  modifiedBy: string;
  deletedOn: number;
  deletedBy: string;
  partner: Partner;
  invoice: Invoice[];
  stop: Stop[];
  drivers: Driver[];
  trucks: Truck[];
  trailers: Trailer[];
  trailerType?: TrailerType;
  isChecked?: boolean;
  computedLoadedMiles?: number;
  computedEmptyMiles?: number;
  loadedMiles?: number;
  emptyMiles?: number;
  truckOrderNotUsed?: boolean;
  paymentAmountTotal?: number;
  previousLoadSummary?: {
    loadId: number;
    loadNumber: string;
    pickupCity: string;
    pickupState: string;
    deliveryState: string;
    deliveryCity: string;
  };

  constructor(companyId?: number) {
    this.companyId = companyId;
    this.partner = new Partner();
    this.stop = [];
    this.drivers = [];
    this.trucks = [];
  }

  static sort(valueX: number, valueY: number, ascending: boolean): number {
    const returnA = ascending ? -1 : 1;
    const returnB = ascending ? 1 : -1;

    if (valueX < valueY) {
      return returnA;
    }
    if (valueX > valueY) {
      return returnB;
    }
    return 0;
  }
}
