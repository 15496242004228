import { Address } from './address';

export class Company {
  _id?: string;
  company_name: string;
  company_email: string;
  company_id?: string;
  transaction_key?: string;
  invoicing?: {
    email: string;
    phone: string;
    mcNumber: string;
    address: Address;
  };
}
