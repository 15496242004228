export class Partner {
  name: string;
  address1: string;
  address2?: string;
  companyId: number;
  city: string;
  state: string;
  postalCode: string;
  isArchived?: string;
  createdOn?: number;
  createdBy?: string;
  modifiedOn?: number;
  modifiedBy?: string;
  archivedOn?: number;
  archivedBy?: string;
  countryId: number;
  stateProvinceId: number;

  constructor(companyId?: number) {
    this.companyId = companyId;
  }
}
