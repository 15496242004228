import Service from './service';
import { AxiosResponse } from 'axios';
import { Load, Partner, Stop } from '../interfaces';
import { PurchaseRequest } from '../interfaces/purchase-request';
import { TMSCompany } from '../interfaces/tms-company';
import { Driver } from '../interfaces/driver';
import { Truck } from '../interfaces/truck';
import { Settings } from '../interfaces/settings';
import { Fuel } from '../interfaces/fuel';
import { LoadListItem } from '../interfaces/load-list-item';
import { Note } from '../interfaces/note';
import { LoadStatuses } from '../interfaces/load-statuses';
import { Endorsement } from '../interfaces/endorsement';
import { FuelLogFilter } from '../interfaces/fuel-log-filter';
import { BusinessPartner } from '../interfaces/business-partner';
import { EmailRequest } from 'interfaces/email-request';
import { BusinessPartnerSearchCriteria } from 'interfaces/business-partner-search-criteria';

const Config = require('Config');

class LoadManagementService extends Service {
  constructor() {
    super(Config.loadManagementServiceUrl);
  }

  async emailInvoice(
    emailRequest: Array<EmailRequest>,
    getProgress?: (progressEvent: { loaded: number; total: number }) => void,
  ): Promise<Array<EmailRequest>> {
    const response: AxiosResponse = await this.axios.post(`loads/invoices/email`, emailRequest, {
      onUploadProgress: getProgress,
    });
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getLoadById(id: number): Promise<Load> {
    const response: AxiosResponse = await this.axios.get(`loads/${id}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getLoadListItemById(id: number): Promise<LoadListItem> {
    const response: AxiosResponse = await this.axios.get(`loads/${id}/loadlistitem`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async saveLoad(load: Load): Promise<Load> {
    let response: AxiosResponse;
    if (load.id) {
      response = await this.axios.patch('loads/', load);
    } else {
      response = await this.axios.post('loads/', { ...load, source: 'load management' });
    }

    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async copyLoad(id: number): Promise<any> {
    let response: AxiosResponse;
    if (id) {
      response = await this.axios.post(`loads/${id}/copy`, {});
    }
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteLoad(id: number): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`loads/${id}`);

    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async submitPurchase(
    request: PurchaseRequest,
    getProgress?: (progressEvent: { loaded: number; total: number }) => void,
  ): Promise<any> {
    let response: AxiosResponse = await this.axios.post('loads/invoices/submit', request, {
      onUploadProgress: getProgress,
    });
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getCompany(mongoCompanyId: string): Promise<TMSCompany> {
    const response: AxiosResponse = await this.axios.get(`company/crm/${mongoCompanyId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async exportInvoices(
    ids: number[],
    offset: number,
    getProgress?: (progressEvent: { loaded: number; total: number }) => void,
  ): Promise<any> {
    let request = { loadIds: ids, invoicedOnOffset: offset };
    let response: AxiosResponse = await this.axios.post('loads/invoices/export', request, {
      onUploadProgress: getProgress,
    });
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getDrivers(): Promise<any> {
    let response: AxiosResponse = await this.axios.get('drivers');
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getTrucks(): Promise<any> {
    let response: AxiosResponse = await this.axios.get('trucks');
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async saveDriver(driver: Driver | BusinessPartner, isNew: boolean): Promise<any> {
    let response: AxiosResponse;
    try {
      if (isNew) {
        response = await this.axios.post('drivers', driver);
      } else {
        response = await this.axios.patch('drivers', driver);
      }
      if (response.status > 299) {
        throw response;
      }
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async savePartner(partner: BusinessPartner, isNew: boolean): Promise<any> {
    let response: AxiosResponse;
    try {
      if (isNew) {
        response = await this.axios.post('businesspartners', partner);
      } else {
        response = await this.axios.patch('businesspartners', partner);
      }
      if (response.status > 299) {
        throw response;
      }
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async saveTruck(truck: Truck, isNew: boolean): Promise<any> {
    let response: AxiosResponse;
    try {
      if (isNew) {
        response = await this.axios.post('trucks', truck);
      } else {
        response = await this.axios.patch('trucks', truck);
      }
      if (response.status > 299) {
        throw response;
      }
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async canDeleteTruck(truck: Truck): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`trucks/${truck.id}/candelete`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteTruck(truck: Truck): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`trucks/${truck.id}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getSettings(companyId: number): Promise<Settings> {
    const response: AxiosResponse = await this.axios.get(`company/settings/${companyId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async saveSettings(settings: Settings): Promise<Settings> {
    const response: AxiosResponse = await this.axios.post(`company/settings`, settings);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async markLoadsAsPaid(ids: number[], date: number, offset: number): Promise<any> {
    let response: AxiosResponse = await this.axios.post('/loads/markaspaid', {
      loadIds: ids,
      invoicePaidOn: date,
      invoicePaidOnOffset: offset,
    });
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getFuelTypes(): Promise<any> {
    let response: AxiosResponse = await this.axios.get('/fuellogs/fueltypes');
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async addFuelLog(entry: Fuel): Promise<any> {
    const entryToAdd = {
      ...entry,
      truckId: entry.truck ? entry.truck.id : entry?.truckId,
      driverId: entry.driver ? entry.driver.id : entry?.driverId,
    };
    let response: AxiosResponse = await this.axios.post(`/fuellogs/`, entryToAdd);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateFuelLog(entry: Fuel): Promise<any> {
    const entryToUpdate = {
      ...entry,
      truckId: entry.truck ? entry.truck.id : entry?.truckId,
      driverId: entry.driver ? entry.driver.id : entry?.driverId,
    };
    let response: AxiosResponse = await this.axios.patch(`/fuellogs/`, entryToUpdate);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteFuelLog(id: number): Promise<any> {
    let response: AxiosResponse = await this.axios.delete(`/fuellogs/${id}`);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getFuelLogCounts(): Promise<any> {
    let response: AxiosResponse = await this.axios.get('/fuellogs/counts');
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async searchFuelLog(filters: FuelLogFilter): Promise<any> {
    let response: AxiosResponse = await this.axios.post('/fuellogs/search', filters);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getFuelFilterList(columnName: string): Promise<any> {
    let response: AxiosResponse = await this.axios.get(`/fuellogs/filter/${columnName}`);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getFuelFilterListExtended(columnName: string, currentFilters: FuelLogFilter): Promise<any> {
    let response: AxiosResponse = await this.axios.post(`/fuellogs/filter/${columnName}`, currentFilters);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getAutomaticImportNumber(): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`fuellogs/imports`);
    if (!response || response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getTrailerTypes(): Promise<any> {
    let response: AxiosResponse = await this.axios.get(`/trailers/types`);
    if (response.status != 200) {
      throw response;
    }
    return response;
  }

  async getCountries(): Promise<any> {
    let response: AxiosResponse = await this.axios.get(`/country`);
    if (response.status != 200) {
      throw response;
    }
    return response.data;
  }

  async getFilterList(columnName: string, currentFilters: string[]): Promise<any> {
    let response: AxiosResponse = await this.axios.post(`/loads/filter/${columnName}`, currentFilters);
    if (response.status != 200) {
      throw response;
    }
    return response.data;
  }

  async filterLoads(requestObject: any): Promise<any> {
    let response: AxiosResponse = await this.axios.post(`/loads/search/`, requestObject);
    if (response.status != 200) {
      throw response;
    }
    return response.data;
  }

  async getNotesByLoadId(id: number): Promise<Note[]> {
    const response: AxiosResponse = await this.axios.get(`/loads/${id}/notes`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async addNoteByLoadId(note: Note, loadId: number): Promise<Note> {
    const response: AxiosResponse = await this.axios.post(`/loads/${loadId}/notes`, note);
    if (response.status !== 201) {
      throw new Error();
    }
    return response.data;
  }

  async deleteNoteById(noteId: number, loadId: number): Promise<Note> {
    const response: AxiosResponse = await this.axios.delete(`/loads/${loadId}/notes/${noteId}`);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async editNoteById(noteId: number, loadId: number, message: string): Promise<Note> {
    const response: AxiosResponse = await this.axios.patch(`/loads/${loadId}/notes/${noteId}`, {
      note: message,
    });
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getNoteCountByLoadId(id: number): Promise<number> {
    const response: AxiosResponse = await this.axios.get(`/loads/${id}/notes/count`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getDocumentCountByLoadId(id: number): Promise<number> {
    const response: AxiosResponse = await this.axios.get(`/loads/${id}/documents/count`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getMissingDocumentFilesByLoadId(id: number): Promise<Number> {
    const response: AxiosResponse = await this.axios.get(`/loads/${id}/documents/missing`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getLoadStatusCounts(): Promise<LoadStatuses> {
    const response: AxiosResponse = await this.axios.get(`/loads/statuscounts`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateLoadProperty(loadId: number, key: string, value: any): Promise<any> {
    try {
      const response: AxiosResponse = await this.axios.patch(`/loads/properties/${loadId}`, [{ key, value }]);
      if (response.status !== 200) throw new Error(response.statusText);
      return response.data;
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.length > 0)
        throw error.response.data[0].message;
      else throw new Error();
    }
  }

  async assignLoadDriver(loadId: number, driverId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.post(`/loads/${loadId}/drivers`, { driverId });

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteLoadDriver(loadId: number, driverId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`/loads/${loadId}/drivers/${driverId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async assignLoadTruck(loadId: number, truckId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.post(`/loads/${loadId}/trucks`, { truckId });

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteLoadTruck(loadId: number, truckId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`/loads/${loadId}/trucks/${truckId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async assignLoadPartner(loadId: number, partner: Partner): Promise<any> {
    const partnerPayload = Object.keys(partner).reduce((acc, key) => {
      return [...acc, { key, value: partner[key] }];
    }, []);
    const response: AxiosResponse = await this.axios.patch(`/loads/${loadId}/partner`, partnerPayload);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async addStopToLoad(loadId: number, stop: Stop): Promise<any> {
    const response: AxiosResponse = await this.axios.post(`/loads/${loadId}/stops`, stop);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateStopOnLoad(loadId: number, stop: Stop): Promise<any> {
    const response: AxiosResponse = await this.axios.patch(`/loads/${loadId}/stops/${stop.id}`, stop);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteStopOnLoad(loadId: number, stopId: number): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`/loads/${loadId}/stops/${stopId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async checkForUnsupportedDocuments(ids): Promise<any> {
    const body = {
      loadIds: ids,
    };
    let response: AxiosResponse = await this.axios.post('/loads/invoices/documents/unsupported', body);
    if (response.status != 200) {
      throw response;
    }
    return response.data;
  }

  async canDeleteDriver(driver: Driver): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`drivers/${driver.id}/candelete`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async canDeletePartner(partner: BusinessPartner): Promise<any> {
    const response: AxiosResponse = await this.axios.get(`businesspartners/${partner.id}/candelete`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteDriver(driver: Driver): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`drivers/${driver.id}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deletePartner(partner: BusinessPartner): Promise<any> {
    const response: AxiosResponse = await this.axios.delete(`businesspartners/${partner.id}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getEndorsements(): Promise<Endorsement[]> {
    const response: AxiosResponse = await this.axios.get('drivers/cdlendorsements');

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getNotesByDriverId(id: number): Promise<Note[]> {
    const response: AxiosResponse = await this.axios.get(`/drivers/${id}/notes`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getNotesByTruckId(id: number): Promise<Note[]> {
    const response: AxiosResponse = await this.axios.get(`/trucks/${id}/notes`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async addNoteByDriverId(note: Note, driverId: number): Promise<Note> {
    const response: AxiosResponse = await this.axios.post(`/drivers/${driverId}/notes`, note);

    if (response.status !== 201) {
      throw new Error();
    }
    return response.data;
  }

  async addNoteByTruckId(note: Note, truckId: number): Promise<Note> {
    const response: AxiosResponse = await this.axios.post(`/trucks/${truckId}/notes`, note);

    if (response.status !== 201) {
      throw new Error();
    }
    return response.data;
  }

  async deleteDriverNoteById(noteId: number, driverId: number): Promise<Note> {
    const response: AxiosResponse = await this.axios.delete(`/drivers/${driverId}/notes/${noteId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async deleteTruckNoteById(noteId: number, truckId: number): Promise<Note> {
    const response: AxiosResponse = await this.axios.delete(`/trucks/${truckId}/notes/${noteId}`);

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async editDriverNoteById(noteId: number, driverId: number, message: string): Promise<Note> {
    const response: AxiosResponse = await this.axios.patch(`/drivers/${driverId}/notes/${noteId}`, {
      note: message,
    });

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async editTruckNoteById(noteId: number, truckId: number, message: string): Promise<Note> {
    const response: AxiosResponse = await this.axios.patch(`/trucks/${truckId}/notes/${noteId}`, {
      note: message,
    });

    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getCompanyLogo(): Promise<any> {
    const response: AxiosResponse = await this.axios.get('company/logo');
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async patchCompanyLogo(companyImageKey): Promise<any> {
    const response: AxiosResponse = await this.axios.patch('company/logo', { companyInvoiceImageKey: companyImageKey });
    if (response.status !== 200) {
      throw new Error();
    }
    return response.data;
  }

  async getBusinessPartners(): Promise<any> {
    let response: AxiosResponse = await this.axios.get('businesspartners');
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async postBusinessPartnerSearch(searchCriteria: BusinessPartnerSearchCriteria): Promise<any> {
    let response: AxiosResponse = await this.axios.post('businesspartners/search', searchCriteria);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async getBusinessPartner(businessPartnerId: number): Promise<any> {
    let response: AxiosResponse = await this.axios.get('businesspartners/' + businessPartnerId);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async postSalesforceLead(data: any): Promise<any> {
    let response: AxiosResponse = await this.axios.post('salesforce/lead', data);
    if (response.status != 200) {
      throw new Error();
    }
    return response.data;
  }

  async updateBusinessPartnerIsActiveState(businessPartnerId: number, isActive: boolean): Promise<any> {
    try {
      let response: AxiosResponse = await this.axios.patch(
        `businesspartners/${businessPartnerId}/isactive/${isActive}`,
      );
      if (response.status > 299) {
        throw response;
      }
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }

  async updateTruckIsActiveState(truckId: number, isActive: boolean): Promise<any> {
    try {
      let response: AxiosResponse = await this.axios.patch(`trucks/${truckId}/isactive/${isActive}`);
      if (response.status > 299) {
        throw response;
      }
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
}

export default LoadManagementService;
