import React, { createContext, useContext, useMemo } from 'react';
import { useSetState } from 'react-use';
import { Step } from 'react-joyride';

export interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  joyrideId: string;
}

type JoyrideStateFunction = (
  patch: Partial<JoyrideState> | ((previousState: JoyrideState) => Partial<JoyrideState>),
) => void;

const joyrideState: JoyrideState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  joyrideId: undefined,
};

export type JoyrideContext = {
  setState: JoyrideStateFunction;
  state?: JoyrideState;
};

export const JoyrideContext = createContext({
  state: joyrideState,
  setState: () => undefined,
});
JoyrideContext.displayName = 'JoyrideContext';

export function JoyrideProvider(props: any) {
  const [state, setState] = useSetState(joyrideState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return <JoyrideContext.Provider value={value} {...props} />;
}

export function useJoyrideContext(): JoyrideContext {
  const context = useContext(JoyrideContext);

  if (!context) {
    throw new Error('useJoyrideContext must be used within a JoyrideProvider');
  }

  return context;
}
