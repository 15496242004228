export class Stop {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  isPickup: boolean;
  stopDate: number;
  loadId: number;
  companyName: string;
  stopOrder: number;
  createdOn: number;
  createdBy: string;
  modifiedOn: number;
  modifiedBy: string;
  deletedOn: number;
  deletedBy: string;
  stopDateOffset?: number;
  isSelected?: boolean;

  static addressLongDisplay(stop: Stop): string {
    if (!stop) {
      return '';
    } else if (stop.address1 && stop.city && stop.state && stop.postalCode) {
      return `${stop.address1}, ${stop.city}, ${stop.state} ${stop.postalCode}`;
    } else if (stop.city && stop.state && stop.postalCode) {
      return `${stop.city}, ${stop.state} ${stop.postalCode}`;
    } else if (stop.address1 && stop.city && stop.state) {
      return `${stop.address1}, ${stop.city}, ${stop.state}`;
    } else if (stop.city && stop.state) {
      return `${stop.city}, ${stop.state}`;
    } else if (!stop.city && stop.state) {
      return `${stop.state}`;
    } else if (stop.city && !stop.state) {
      return `${stop.city}`;
    }
  }

  static addressShortDisplay(stop: Stop): string {
    if (!stop) {
      return '';
    } else if (stop.city && stop.state) {
      return `${stop.city}, ${stop.state}`;
    } else if (!stop.city && stop.state) {
      return `${stop.state}`;
    } else if (stop.city && !stop.state) {
      return `${stop.city}`;
    }
  }

  static getTimeDisplay(stop: Stop, seconds = true): string {
    if (stop && stop.stopDate) {
      const epoch = stop.stopDateOffset
        ? stop.stopDate + (new Date().getTimezoneOffset() / 60 + stop.stopDateOffset) * 3600 * 1000
        : stop.stopDate;
      const date = new Date(epoch);
      if (seconds) {
        return date.toLocaleTimeString();
      } else {
        // @ts-ignore this is valid
        return date.toLocaleTimeString([], { timeStyle: 'short' });
      }
    } else {
      return '';
    }
  }
  static getDateDisplay(stop: Stop): string {
    if (stop && stop.stopDate) {
      const epoch = stop.stopDateOffset
        ? stop.stopDate + (new Date().getTimezoneOffset() / 60 + stop.stopDateOffset) * 3600 * 1000
        : stop.stopDate;
      const date = new Date(epoch);
      return date.toLocaleDateString();
    } else {
      return '';
    }
  }
}
