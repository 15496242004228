import React, { ReactPropTypes, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FirebaseAnalytics from '../core/firebase-analytics';
import circleIcon from '../../assets/upgrade_circle.svg';
import arrowUpIcon from '../../assets/upgrade_arrow_up.svg';
import css from './styles/upgrade-banner.scss';
import { useTranslation } from 'react-i18next';

interface Props extends Partial<ReactPropTypes> {
  hasProTransLite?: boolean;
  setIsShowingUpgradeBanner?: Function;
  isFinancial?: boolean;
}

const UpgradeBanner: React.FC<Props> = ({ hasProTransLite, setIsShowingUpgradeBanner, isFinancial }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tier = hasProTransLite ? 'Premium' : 'Basic';
  const showUpgradeBanner =
    location.pathname !== '/loads/requestupgrade' &&
    !(location.pathname === '/loads/reports/aging' && isFinancial && !hasProTransLite);

  useEffect(() => {
    setIsShowingUpgradeBanner(showUpgradeBanner);
  }, [setIsShowingUpgradeBanner, showUpgradeBanner]);

  const handleOnClick = () => {
    FirebaseAnalytics.sendEvent(
      FirebaseAnalytics.EVENTS.CLICK,
      FirebaseAnalytics.MODULES.LOADS,
      FirebaseAnalytics.PAGES.SUB_HEADER,
      { label: FirebaseAnalytics.LABELS.UPGRADE_PLAN },
    );

    navigate('/loads/requestupgrade');
  };
  const { t } = useTranslation();
  return showUpgradeBanner ? (
    <div className={css.containerWrapper}>
      <div className={css.container}>
        <div className={css.bannerLabel}>
          {t('upgrade_your')}
          <div className={css.planName}>{`ProTransport ${tier} Plan`}</div>
          {t('to_unlock_more_features')}.
        </div>
        <Button
          className={css.upgradeButton}
          color="primary"
          size="medium"
          variant="outlined"
          classes={{ label: css.label }}
          onClick={() => handleOnClick()}
        >
          <img src={circleIcon} className={css.circleIcon} />
          <img src={arrowUpIcon} className={css.arrowIcon} />
          {t('upgrade_plan')}
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default UpgradeBanner;
