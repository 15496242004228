import { DateProvider } from './date-provider';
import moment from 'moment';

export const getLocalEpoch = (unixMilliseconds: number, offset?: number) => {
  return unixMilliseconds + (new Date().getTimezoneOffset() / 60 + (offset || -5)) * 3600 * 1000;
};

export const getDateFromEpoch = (unixMilliseconds: number, offset?: number) => {
  const epoch = getLocalEpoch(unixMilliseconds, offset);
  return new Date(epoch);
};

export const getDisplayDateFromEpoch = (unixMilliseconds: number, offset?: number) => {
  const dateString = getDateFromEpoch(unixMilliseconds, offset);
  return dateString.toLocaleDateString();
};

export const getEpochAndOffset = (date = new Date(), utc = false) => {
  const millisecondsSinceEpoch = date.getTime();
  const utcMillisecondsSinceEpoch = millisecondsSinceEpoch - date.getTimezoneOffset() * 60000;
  return {
    unix: utc ? utcMillisecondsSinceEpoch : millisecondsSinceEpoch,
    offset: date.getTimezoneOffset() / -60,
  };
};

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return undefined;
  } else {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return formattedDate;
  }
};

export const getTimeString = (unixMilliseconds: number, offset?: number) => {
  if (!unixMilliseconds) {
    return undefined;
  } else {
    const epoch = offset
      ? unixMilliseconds + (new Date().getTimezoneOffset() / 60 + offset) * 3600 * 1000
      : unixMilliseconds;
    const date = new Date(epoch);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  }
};

export const setMomentLocale = (locale: string) => {
  if (locale === 'es') {
    // NOTE: Moment defaults all these to lowercase
    // es adds a percent symbol in place of the 'th' in 25th. es-es does not.
    moment.updateLocale('es-es', {
      months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
      monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
      weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
      weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sáb'.split('_'),
      weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    });
  } else {
    moment.locale(locale);
  }
};

export const getTimezoneOffsetInHours = (): number => {
  return new Date().getTimezoneOffset() / -60;
};

export const isToday = (date: Date | string | moment.Moment) => {
  const now = new Date();
  if (typeof date === 'string') {
    date = new Date(`${date}T00:00`);
  }
  if (date instanceof moment) {
    date = (date as moment.Moment).toDate();
  }
  return (
    (date as Date).getDate() === now.getDate() &&
    (date as Date).getMonth() === now.getMonth() &&
    (date as Date).getFullYear() === now.getFullYear()
  );
};

export const getCurrentIftaReportingQuarter = (
  dateProvider: DateProvider = new DateProvider(),
): { reportingYear: number; reportingQuarter: number } => {
  let year = dateProvider.currentDate().getFullYear();
  let quarter = Math.ceil((dateProvider.currentDate().getMonth() + 1) / 3) - 1;
  if (quarter === 0) {
    quarter = 4;
  }
  if (quarter === 4) {
    year = year - 1;
  }
  return { reportingYear: year, reportingQuarter: quarter };
};

export const getNextIftaReportingStartDate = (
  dateProvider: DateProvider = new DateProvider(),
): { startingMonth: string; startingYear: number } => {
  const reportingStartMonths = ['July', 'October', 'January', 'April'];
  const { reportingYear, reportingQuarter } = getCurrentIftaReportingQuarter(dateProvider);
  const startingMonth = reportingStartMonths[reportingQuarter - 1];
  let startingYear: number = reportingYear;
  if (reportingQuarter === 3 || reportingQuarter === 4) {
    startingYear = reportingYear + 1;
  }
  return { startingMonth, startingYear };
};

const getISODate = (inputDate: Date) => {
  return new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000).toISOString();
};

export const toPlainDateString = (inputDate: Date) => {
  const isoDate = getISODate(inputDate);
  return isoDate.split('T')[0];
};

export const toPlainDateTime = (inputDate: Date) => {
  const isoDate = getISODate(inputDate);
  return isoDate.slice(0, isoDate.length - 5);
};

export const toPlainTime = (inputDate: Date, displaySeconds: boolean = true) => {
  const isoDate = getISODate(inputDate);
  const timeZone = isoDate.split('T')[1];
  const end = displaySeconds ? timeZone.length - 5 : timeZone.length - 8;
  return timeZone.slice(0, end);
};

export const elapsedMinutes = (date1: Date, date2: Date) => {
  const startTime = new Date(date1).getTime();
  const endTime = new Date(date2).getTime();
  const differenceTime = endTime - startTime;
  const elapsedMinutes = differenceTime / (1000 * 60);
  return Math.floor(elapsedMinutes);
};

export const elapsedTimeBlocks = (date1: Date, date2: Date, blockMinutes: number) => {
  const minutes = elapsedMinutes(date1, date2);
  const blocks = minutes / blockMinutes;
  return Math.floor(blocks);
};
