export default class Document {
  _id: string;
  name: string;
  key: string;
  companyId: string;
  transactionId: string;
  thumbnail: string;
  createdBy?: string;
  createdOn?: string;
  modifiedBy: string;
  modifiedOn: string;
  isNew?: boolean;
  missingExtension?: boolean;
  zeroBytes?: boolean;
  size?: number;
}
