import React, { useState } from 'react';
import SubHeader from '../../components/headers/sub-header';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../../components/core/with-router';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/appActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import RequestUpgrade from '../../components/company-plan/request-upgrade';
import { FeatureFlags } from '../../enums/featureFlagEnum';
import UpgradeBanner from '../../components/headers/upgrade-banner';
import Loadable from 'react-loadable';
import LoadingSpinner from '../../components/core/loading-spinner';
import MaintenanceAlertDialog from '../../components/alerts/maintenance-alert-dialog';
import Joyride from '../../components/joyride/joyride';
import { JoyrideProvider } from '../../components/joyride/context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import css from './federated-app.scss';
import ProtectedPage from './protected-page';
import DispatchTest from '../../components/dispatch/dispatch-test';

const Dashboard = Loadable({
  loader: () => import('ptmodules/Dashboard'),
  loading: LoadingSpinner,
});

const Loads = Loadable({
  loader: () => import('../../components/loads/loads-premium'),
  loading: LoadingSpinner,
});

const LoadsStandard = Loadable({
  loader: () => import('../../components/loads/loads-basic'),
  loading: LoadingSpinner,
});

const AdvancedLoads = Loadable({
  loader: () => import('../../components/advanced-loads/advanced-loads'),
  loading: LoadingSpinner,
});

const DispatchContainer = Loadable({
  loader: () => import('../../components/dispatch/dispatch-container'),
  loading: LoadingSpinner,
});

const AgingReport = Loadable({
  loader: () => import('../../components/aging/aging-report'),
  loading: LoadingSpinner,
});

const AgingReportStatic = Loadable({
  loader: () => import('../../components/aging/aging-report-static'),
  loading: LoadingSpinner,
});

const FuelEntry = Loadable({
  loader: () => import('../../components/fuel/fuel-entry'),
  loading: LoadingSpinner,
});

const IFTAReports = Loadable({
  loader: () => import('../../components/ifta/ifta-reports'),
  loading: LoadingSpinner,
});

const CompanyInfo = Loadable({
  loader: () => import('../../components/company-info/company-info'),
  loading: LoadingSpinner,
});

const MyFleet = Loadable({
  loader: () => import('../../components/my-fleet/my-fleet'),
  loading: LoadingSpinner,
});

const MyFleetBasic = Loadable({
  loader: () => import('../../components/my-fleet/my-fleet-basic'),
  loading: LoadingSpinner,
});

const Statements = Loadable({
  loader: () => import('../../components/statements/statements-premium'),
  loading: LoadingSpinner,
});

const StatementsBasic = Loadable({
  loader: () => import('../../components/statements/statements-basic'),
  loading: LoadingSpinner,
});

const Router = ({ isAuthed, features, permissions, clientNumber, factoringClientSelector, header }: any) => {
  const dispatch = useDispatch();
  const maintenanceAlert = useSelector((state: any) => state.app.maintenanceAlert);
  const bypassMaintenance = features && features.includes(FeatureFlags.MAINTENANCE_BYPASS);
  const proTransLite = !!permissions?.find((p: any) => p.name === 'ProTransportWeb.user' && p.status === 'active');
  const isFinancial = !!permissions?.find((p: any) => p.name === 'invoicemanager.User' && p.status === 'active');
  const removeUpgradeBanner = features.includes(FeatureFlags.REMOVE_UPGRADE_BANNER);
  const [isShowingUpgradeBanner, setIsShowingUpgradeBanner] = useState(!removeUpgradeBanner);
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const pageContainerRef = React.useRef(null);
  const reduxClientNumber = useSelector((state: any) => state.app.clientNumber);
  const statementsElement = proTransLite ? <Statements /> : <StatementsBasic />;
  const isDispatchFFEnabled = features.includes(FeatureFlags.DISPATCH);
  const sideNavigationFF = features.includes(FeatureFlags.SIDE_NAVIGATION);
  const advancedLoads = features.includes(FeatureFlags.ADVANCED_LOADS);
  const headerHeight = 41 + (isShowingUpgradeBanner ? 75 : 0) + (sideNavigationFF && isFinancial ? 30 : 0);

  React.useEffect(() => {
    dispatch(appActions.getMaintenanceAlert());
  }, []);

  React.useEffect(() => {
    const creditIdQueryParam = location.search.includes('?id=');
    if (proTransLite && location.pathname === '/loads' && !creditIdQueryParam) {
      navigate('/loads/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proTransLite]);

  const getAgingComponent = () => {
    if (isFinancial && !proTransLite) {
      return <AgingReportStatic />;
    } else {
      return <AgingReport hasProTransLite={proTransLite} isFinancial={isFinancial} />;
    }
  };

  const reduxClientNumberUpdated = clientNumber === reduxClientNumber;

  React.useEffect(() => {
    dispatch(appActions.setClientNumber(clientNumber));
  }, [clientNumber]);

  if (reduxClientNumberUpdated) {
    return (
      <>
        {isAuthed && (
          <div style={{ height: 'calc(100% + 2px)', marginTop: '-2px' }}>
            {maintenanceAlert.visible && !bypassMaintenance && (
              <MaintenanceAlertDialog maintenanceAlert={maintenanceAlert} />
            )}
            <JoyrideProvider>
              <Joyride />
              {sideNavigationFF && isFinancial && <div>{factoringClientSelector}</div>}
              <SubHeader proTransLite={proTransLite} prefix={'/loads'} features={features} header={header} />
              {!removeUpgradeBanner && (
                <UpgradeBanner
                  hasProTransLite={proTransLite}
                  isFinancial={isFinancial}
                  setIsShowingUpgradeBanner={setIsShowingUpgradeBanner}
                />
              )}
              <div
                className={css.pageContainer}
                ref={pageContainerRef}
                style={{
                  height: `calc(100% - ${headerHeight}px)`,
                }}
              >
                <Routes>
                  <Route path={'/loads/ifta'} element={<IFTAReports />} />
                  <Route path={'/loads/reports/aging'} element={getAgingComponent()} />
                  <Route path={'/loads/fuel'} element={<FuelEntry />} />
                  <Route path={'/loads/myfleet'} element={proTransLite ? <MyFleet /> : <MyFleetBasic />} />
                  <Route path={'/loads/company'} element={<CompanyInfo />} />
                  <Route path={'/loads/requestupgrade'} element={<RequestUpgrade hasProTransLite={proTransLite} />} />
                  <Route path={'/loads/statements'} element={statementsElement} />
                  <Route
                    path={'/loads/dispatch-test'}
                    element={
                      <ProtectedPage
                        element={<DispatchTest />}
                        isAuthorized={isDispatchFFEnabled}
                        reroutePath="/loads"
                      />
                    }
                  />
                  <Route
                    path={'/loads/dispatch'}
                    element={
                      <ProtectedPage
                        element={<DispatchContainer />}
                        isAuthorized={isDispatchFFEnabled}
                        reroutePath="/loads"
                      />
                    }
                  />
                  <Route
                    path={'/loads/dashboard'}
                    element={
                      <ProtectedPage
                        element={
                          <div className={css.dashboard}>
                            <Dashboard i18n={i18n} hostApp={'load-management'} />
                          </div>
                        }
                        isAuthorized={proTransLite}
                        reroutePath="/loads"
                      />
                    }
                  />
                  <Route
                    path={'/loads/loads_new'}
                    element={
                      <ProtectedPage element={<AdvancedLoads />} isAuthorized={advancedLoads} reroutePath="/loads" />
                    }
                  />
                  {/* WARN: Add new routes before the /loads route  */}
                  <Route path={'/loads'} element={proTransLite ? <Loads /> : <LoadsStandard />} />
                </Routes>
              </div>
            </JoyrideProvider>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  const { app } = state;
  return {
    isAuthed: !!app.token && !!app.user && !!app.tmsCompany && !!app.company,
    permissions: app.user?.permissions,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(appActions, dispatch) };
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Router));
