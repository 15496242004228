import { combineReducers } from 'redux';
import loads from './loadReducer';
import statements from './statementReducer';
import app from './appReducer';
import drivers from './driverReducer';
import trucks from './truckReducer';
import fuel from './fuelReducer';
import ifta from './iftaReducer';
import equipment from './equipmentReducer';
import businessPartners from './businessPartnerReducer';
import { agingReport } from './agingReducer';
import dispatch from './dispatchReducer';

const rootReducer = combineReducers({
  loads,
  statements,
  app,
  drivers,
  trucks,
  fuel,
  ifta,
  businessPartners,
  agingReport,
  equipment,
  dispatch,
});

export default rootReducer;
