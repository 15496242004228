import { DriverSchedule } from 'interfaces/driver-schedule';
import * as DispatchConstants from '../../constants/dispatchConstants';

interface DispatchStoreState {
  startDate: Date;
  endDate: Date;
  driverSchedules: DriverSchedule[];
  filteredDriverSchedules: DriverSchedule[];
}

const initialState: DispatchStoreState = {
  startDate: new Date(),
  endDate: new Date(),
  driverSchedules: [],
  filteredDriverSchedules: [],
};

export default function dispatchReducer(state = initialState, action: any) {
  switch (action.type) {
    case DispatchConstants.SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case DispatchConstants.SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case DispatchConstants.SET_DRIVER_SCHEDULES:
      return {
        ...state,
        driverSchedules: action.schedules,
      };
    case DispatchConstants.SET_FILTERED_DRIVER_SCHEDULES:
      return {
        ...state,
        filteredDriverSchedules: action.schedules,
      };
    default:
      return state;
  }
}
