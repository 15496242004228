import { CallBackProps } from 'react-joyride';
import { IJoyride } from '../IJoyride';
import { Joyride } from '../joyride';

export class JoyrideTms extends Joyride implements IJoyride {
  handleCallback(data: CallBackProps): void {
    const { action, index, lifecycle, type } = data;

    if (lifecycle === 'init' && action === 'start' && index === 0) {
      this.params.navigate('loads/company');
      this.handleJoyrideStepAnalyticsClick(0);
    }

    if (action === 'skip') {
      this.handleSkipJoyrideAnalyticsClick();
      this.updateUserJoyride({ tms: true });
      this.context.setState({ run: false, stepIndex: 0, tourActive: false, steps: [], joyrideId: undefined });
    }

    if (type === 'step:after' && action === 'prev' && index === 1) {
      this.context.setState({ run: false });
      this.params.navigate('loads/company');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 0 });
      }, 400);
    }

    if (
      (type === 'step:after' && action === 'next' && index === 0) ||
      (type === 'step:after' && action === 'prev' && index === 2)
    ) {
      this.context.setState({ run: false });
      this.params.navigate('loads/myfleet');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 1 });
      }, 400);
      this.handleJoyrideStepAnalyticsClick(1);
    }

    if (
      (type === 'step:after' && action === 'next' && index === 1) ||
      (type === 'step:after' && action === 'prev' && index === 3)
    ) {
      this.context.setState({ run: false });
      this.params.navigate('loads');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 2 });
      }, 400);
      this.handleJoyrideStepAnalyticsClick(2);
    }

    if (type === 'step:after' && action === 'next' && index === 2) {
      this.context.setState({ run: false });
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 3 });
      }, 200);
      this.handleJoyrideStepAnalyticsClick(3);
    }

    if (type === 'step:after' && action === 'next' && index === 3) {
      this.context.setState({ run: false });
      this.params.navigate('loads/statements');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 4 });
      }, 400);
      this.handleJoyrideStepAnalyticsClick(4);
    }

    if (type === 'step:after' && action === 'prev' && index === 4) {
      this.context.setState({ run: false });
      this.params.navigate('loads');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 3 });
      }, 400);
    }

    if (type === 'step:after' && action === 'prev' && index === 5) {
      this.context.setState({ run: false });
      this.params.navigate('loads/statements');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 4 });
      }, 400);
    }

    if (type === 'step:after' && action === 'next' && index === 4) {
      this.context.setState({ run: false });
      this.params.navigate('loads/dashboard');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 5 });
      }, 400);
    }

    if (type === 'step:after' && index === 5 && lifecycle === 'complete' && action !== 'prev') {
      this.updateUserJoyride({ tms: true });
      this.context.setState({ run: false, stepIndex: 0, tourActive: false, steps: [], joyrideId: undefined });
      this.params.navigate('loads/company');
    }
  }
}
