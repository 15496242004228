import { CallBackProps } from 'react-joyride';
import { IJoyride } from '../IJoyride';
import { Joyride } from '../joyride';

export class JoyrideAgingReport extends Joyride implements IJoyride {
  handleCallback(data: CallBackProps): void {
    const { action, index, lifecycle, type } = data;

    if (lifecycle === 'init' && action === 'start' && index === 0) {
      this.updateUserJoyride({ tmsAgingReport: true });
    }

    if (action === 'skip') {
      this.context.setState({ run: false, stepIndex: 0, tourActive: false, steps: [], joyrideId: undefined });
    }

    if (type === 'step:after' && action === 'prev' && index === 1) {
      this.context.setState({ run: false });
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 0 });
      }, 400);
    }

    if (
      (type === 'step:after' && action === 'next' && index === 0) ||
      (type === 'step:after' && action === 'prev' && index === 2)
    ) {
      this.context.setState({ run: false });
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 1 });
      }, 400);
    }

    if (
      (type === 'step:after' && action === 'next' && index === 1) ||
      (type === 'step:after' && action === 'prev' && index === 3)
    ) {
      this.context.setState({ run: false });
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 2 });
      }, 400);
    }

    const isFinancial = !!this.params.user?.permissions.find(
      permission => permission.name === 'invoicemanager.User' && permission.status.toLowerCase() === 'active',
    );

    if (type === 'step:after' && action === 'next' && index === 2 && isFinancial) {
      this.context.setState({ run: false });
      this.params.navigate('loads/reports/aging?tab=factored');
      setTimeout(() => {
        this.context.setState({ run: true, stepIndex: 3 });
      }, 400);
    }

    if (type === 'step:after' && index === (isFinancial ? 3 : 2) && lifecycle === 'complete' && action !== 'prev') {
      this.context.setState({ run: false, stepIndex: 0, tourActive: false, steps: [], joyrideId: undefined });
    }
  }
}
