// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".loading-spinner__loadingContainer___3ipLh {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 19;\n  background-color: #888888;\n  opacity: 0.3;\n}\n\n.loading-spinner__loadingSpinner___3SWD1 {\n  position: absolute;\n  top: calc(50% - 40px);\n  left: calc(50% - 30px);\n  z-index: 9999;\n}", "",{"version":3,"sources":["/codebuild/output/src927635106/src/src/components/core/styles/loading-spinner.scss","loading-spinner.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;ACCJ;;ADEA;EACI,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,aAAA;ACCJ","file":"loading-spinner.scss","sourcesContent":[".loadingContainer {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    z-index: 19;\n    background-color: #888888;\n    opacity: 0.3;\n}\n\n.loadingSpinner {\n    position: absolute;\n    top: calc(50% - 40px);\n    left: calc(50% - 30px);\n    z-index: 9999;\n}",".loadingContainer {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  z-index: 19;\n  background-color: #888888;\n  opacity: 0.3;\n}\n\n.loadingSpinner {\n  position: absolute;\n  top: calc(50% - 40px);\n  left: calc(50% - 30px);\n  z-index: 9999;\n}"]}]);
// Exports
exports.locals = {
	"loadingContainer": "loading-spinner__loadingContainer___3ipLh",
	"loadingSpinner": "loading-spinner__loadingSpinner___3SWD1"
};
module.exports = exports;
